import React, { ErrorInfo, ReactNode } from 'react';
import { Link } from "react-router-dom";
import { error } from "../../assets/images";
import { Styled404Message, Styled404Section, Styled404Title } from './styled';
import MetaHelmet from '../MetaHelmet';
import Button from '../Button';
import { WarningDiamond, ArrowsClockwise } from "../../assets/icons/index";


interface IErrorBoundaryProps {
  children: ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error): IErrorBoundaryState {
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  componentDidUpdate(prevProps: IErrorBoundaryProps) {
    if (prevProps.children !== this.props.children) {
      this.setState({ hasError: false, error: null, errorInfo: null });
    }
  }
  // Fallback for browsers without Cache API
  handleRefresh = () => {
    window.location.replace(window.location.href);
  };

  handleHardReload = async (url: string) => {
    await fetch(url + '?_=' + new Date().getTime(), {
      headers: {
        'Cache-Control': 'no-cache',
        Expires: '-1',
      },
    });
    window.location.href = url;
    // This is to ensure reload with url's having '#'
    window.location.reload();
  };

  clearCacheAndRefresh = async ()=> {
    if (!('caches' in window)){
      this.handleRefresh()
      return;
    }
    try {
      // Clear all caches
      const cacheKeys = await caches.keys();
      await Promise.all(cacheKeys.map(key => caches.delete(key)));
      
      // Reload page
      window.location.reload();
    } catch (error) {
        console.error('Cache clearing failed:', error);
        this.handleRefresh()
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Styled404Section>
        <MetaHelmet
          title="Something went wrong !"
          description="an error occured, we're working on it"
          noindex={true}
        />
        
        <Styled404Title >Oops!</Styled404Title>
        <Styled404Message>
          Something Went Wrong
        </Styled404Message>

        <div style={{display: 'flex', gap: '1rem', justifyContent: 'center'}}>
        <Button
          style={{border: '1px solid rgba(112, 45, 255, 0.18)', color: "#202020", fontWeight: 400}}
          color="rgba(153, 111, 244, 0.18)"
          onClick={ () => this.handleHardReload(window.location.href)}
        >
          <ArrowsClockwise color="#202020" weight="light" size={24} />
          <b>Reload</b>
        </Button>
        <Button
          style={{border: '1px solid rgba(112, 45, 255, 0.18)', color: "#202020", fontWeight: 400}}
          color="rgba(153, 111, 244, 0.18)"
          as={Link}
          to="/contact"
        >
          <WarningDiamond color="#202020" weight="light" size={24} />Report Issue
        </Button>
        </div>
        <img src={error} alt="unexpected error" onDoubleClick={()=> this.clearCacheAndRefresh()}/>
        
      </Styled404Section>
      );
    } else { 
        return this.props.children;
    }
  }
}

export default ErrorBoundary;
