
import { fableboxApi } from "../api";


export const booksApi = fableboxApi.injectEndpoints({
  endpoints: (builder)=> ({
    getUserBooks: builder.query<IBook[], void | {limit?: number}>({
      query: (args)=> {
        const limit = args && 'limit' in args ? args.limit : undefined;
        return {
          url: '/book/get-user-books/',
          params: limit ? { limit } : undefined,
        };
      },
      keepUnusedDataFor: 300,
      providesTags: [{type: "Books", id: 'user_list'}]
    }),
    getReviewBooksCount: builder.query<number, void>({
      query: ()=> '/book/get-review-books-count',
    }),
    getReviewBooks: builder.query<IBook[], void>({
      query: ()=> '/book/get-review-books',
      keepUnusedDataFor: 300,
      providesTags: [{type: "Books", id: 'admin_list'}]
    }),
    getReviewBook: builder.query<IBook, string>({
      query: (bookId)=> '/book/get-review-book/' + bookId,
      keepUnusedDataFor: 300,
      providesTags: (_res, _err, bookId)=>  [{type: "Books", id: bookId}]
    }),
    generateStory: builder.mutation<IBook, IGenerateStoryReqParams>({
      query: (params)=> ({
        url: '/book/generate-story',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (result)=>  result?._id ? [{type: "Books", id: "orders"}]: [],
      async onQueryStarted(_args, {dispatch, queryFulfilled}){
        try {
          const {data: generatedBook} = await queryFulfilled; // pessimistic update
          dispatch(
            booksApi.util.updateQueryData('getUserBooks', {limit: undefined}, (draft)=> {
              draft.unshift(generatedBook);
            })
          )
        } catch (err) {
          console.log(err)
        }
      }
    }),
    submitPageReview: builder.mutation<IBook, { formData: FormData}>({
      query: ({ formData}) => {    
        return {
          url: '/book/submit-page-review',
          method: 'PATCH',
          body: formData,
        };
      },
      async onQueryStarted(args, {dispatch, queryFulfilled}){
        try {
          const {data: updatedBook} = await queryFulfilled; // pessimistic update
          const bookId = args.formData.get("bookId") as string;
          const pageIdx = args.formData.get("pageIdx") as string;
          dispatch(
            booksApi.util.updateQueryData('getReviewBooks', undefined, (draft)=> {
              if (draft?.length){
                const bookIndex = draft.findIndex((book) => book._id === bookId);
                if (bookIndex === -1) return;
                if (+pageIdx === 0) {
                  draft[bookIndex].cover = updatedBook.cover
                } else {
                  draft[bookIndex].images  = updatedBook.images
                }
              }
            })
          )
          dispatch(
            booksApi.util.updateQueryData('getReviewBook', bookId, (draft)=> {
              if (draft) {
                if (+pageIdx === 0) {
                  draft.cover = updatedBook.cover
                } else {
                  draft.images  = updatedBook.images
                }
              } 
            })
          )
        } catch (err) {
          console.log(err)
        }
      }
    }),
    regeneratePage: builder.mutation<{run_id: string, pageIdx: number}, { bookId: string, pageIdx: number, charDesc: string}>({
      query: (params) => {    
        return {
          url: '/book/regen-book-page',
          method: 'POST',
          body: params,
        };
      },
    }),
    getAIRunResult: builder.query<{isDone: boolean, resultUrl?: string}, string>({
      query: (runId)=> '/book/run-result/' + runId,
    }),
    approveBook: builder.mutation<IBook, string>({
      query: (bookId) => {    
        return {
          url: '/book/approve-book/' + bookId,
          method: 'PATCH',
        };
      },
      invalidatesTags: (res) => res ?  [{type: "Books", id: 'admin_list'}] : []
    }),
    regenerateBookStory: builder.mutation<GenericResponse, string>({
      query: (bookId)=> ({
          url: '/book/regen-book-story/' + bookId,
          method: 'POST' 
      }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}){
        await queryFulfilled
        dispatch(
          booksApi.util.updateQueryData('getReviewBooks', undefined, (draft)=> {
            const bookIdx = draft.findIndex(book=> book._id === arg)
            draft.splice(bookIdx, 1);
          })
        )
      }
    }),
    deleteBook: builder.mutation<GenericResponse, string>({
      query: (bookId)=> ({
        url: '/book/admin-delete/' + bookId,
        method: 'DELETE' 
      }),
      invalidatesTags: (result)=> result? [{type: "Books"}] : [],
    })
  }),
  overrideExisting: false
})


export const {
  useGetUserBooksQuery,
  useGetReviewBooksQuery,
  useGetReviewBookQuery,
  useGenerateStoryMutation,
  useSubmitPageReviewMutation,
  useRegeneratePageMutation,
  useApproveBookMutation,
  useLazyGetAIRunResultQuery,
  useRegenerateBookStoryMutation,
  useDeleteBookMutation,
  useGetReviewBooksCountQuery
} = booksApi;