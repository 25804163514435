import styled, { css } from "styled-components";
import { getColor, mediaQuery } from "../../utils";
import { NavLink } from "react-router-dom";
import { setInterTypo } from "../../utils/setTypo";
import {List } from "../../assets/icons";
import Button from "../Button";


export const StyledHeaderStickyWrapper = styled.div<{$inApp: boolean}>`
  border-radius: 1.2rem;
  background-color: ${({theme})=> getColor(theme, "white", 90)};
  position: sticky;
  z-index: 15;
  max-width: calc(100vw - 1.5rem);
  border-bottom: 1px dashed #996FF4;
  top: 0;
  left: 0.75rem;
  height: fit-content;

  ${mediaQuery("lg")}{
    left: 0;
    ${({$inApp})=> $inApp? 
      css`
        height: 95.5vh;
        top: 2.25vh;
        border-bottom-width: 0;
      `
      :
      css`
        border-bottom: 1px dashed #996FF4;
        top: 0;
        height: auto;
      ` }
  }

`;

export const StyledHeader = styled.header`
  background-color: ${({theme})=> getColor(theme, "white", 90)};
  padding: 2rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 1.2rem;
  height: 100%;
  width: 100%;

  ${mediaQuery("lg")}{
    padding: 3rem;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 140rem;
    margin: 0 auto;
    border-radius: 1.2rem;
  }
`;

export const StyledHamburger = styled(List)`
  cursor: pointer;
  flex-shrink: 0;
  ${mediaQuery('lg')} {
    display: none;
  }
`;

interface IStyledNavProps {
  $isOpen: boolean;
  $noUser?: boolean;
}

export const StyledNavOverlay = styled.div<IStyledNavProps>`
  position: ${({ $isOpen }) => $isOpen ? 'fixed' : 'static'};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: ${({theme, $isOpen})=> $isOpen? getColor(theme, 'gray', 40) : 'transparent'};
  transition: 0.5s all ease;
  
  ${mediaQuery('lg')} {
    background-color: transparent;
    position: static;
    width: 100%;
    height: 100%;
  }
`;

export const StyledNav = styled.nav<IStyledNavProps>`
  background-color: ${({theme})=> getColor(theme, "white", 90)};
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 60%;
  position: fixed;
  left: ${({$isOpen})=> $isOpen ? '0': '-62%'};
  top: 0;
  bottom: 0;
  z-index: 4;
  padding: 2.5rem;
  transition: 0.5s all ease;
  height: 100%;

  ${mediaQuery('md')}{
    width: 35%;
    padding: 3rem;

  }

  ${mediaQuery('lg')} {
    background-color: transparent;
    position: static;
    z-index: auto;
    width: auto;
    gap: 2rem;
    padding: 0;
    ${({$noUser})=> $noUser && css`
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `}
  }
`;

export const StyledNavSection = styled.div<{$noUser?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  & > strong {
    ${setInterTypo({size: 1.6, weight: 800, lineHeight: 1.3,})};
    color: ${({theme})=> theme.brightPurple};
    display: inline-block;
    text-transform: capitalize;
  }
  ${mediaQuery('lg')}{
    ${({$noUser})=> $noUser && css`
      flex-direction: row;
      gap: 4rem;
      & > strong {
        display: none;
      }
    `}
  }
`;

export const StyledNavLink = styled(NavLink)`
  ${setInterTypo({size: 1.6, weight: 500})};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({theme})=> theme.darkGray};
  
  & svg {
    fill: ${({theme})=> theme.darkGray};
  }
  &.active {
    color: ${({theme})=> theme.brightPurple};
  }
  &.active > svg {
    fill: ${({theme})=> theme.brightPurple};
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledLogoLink = styled(NavLink)`
  cursor: pointer;
  flex-shrink: 0;
  margin-left: -10%;
  ${mediaQuery('md')}{
    margin-left: -25%;
  }
  ${mediaQuery('lg')} {
    display: none;
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 12px;
  border: 1px solid #9284B0;
  background-color: ${({theme})=> theme.brightPurple};
  color: white;
  padding: 1rem 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 16rem;
  gap: 0.5rem;
  ${setInterTypo({size: 1.6, weight: 700})};
  `
  
  
  export const StyledMobileButton = styled(StyledButton)`
  ${setInterTypo({size: 1.5, weight: 700})};
  padding: 1rem 1.3rem;
  ${mediaQuery('lg')} {
    display: none;
  }

`