import styled, { css } from "styled-components";
import { colors } from "../../GlobalStyles";
import { mediaQuery } from "../../utils/mediaQuery";


const { lightGray, black, whitishRed, red, lightGreen, deepGreen } = colors;

export const alertColors = {
  neutral: {
    background: lightGray,
    color: black,
  },
  error: {
    background: whitishRed,
    color: red,
  },
  success: {
    background: lightGreen,
    color: deepGreen,
  }
}

interface StyledAlertProps {
  $state: string;
  $pop?: boolean;
}

export const StyledMessageWrapper = styled.div<StyledAlertProps>`
  position: fixed;
  top: ${({$pop})=> $pop? "5%": "-30%"};
  right: 10%;
  z-index: 25;
  width: 80%;
  margin: 0 auto;
  padding: 1rem 2rem;
  border-radius: 5px;
  ${({$state})=> {
    const {background, color} = alertColors[$state as keyof (typeof alertColors)];
    return css`
      background-color: ${background};
      border: 1px solid ${color};
    `;
    
  }}
  display: flex;
  justify-content: center;
  gap: 1rem;
  transition: all 0.4s ease-in;

  & > .x {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 9px;
  }
  ${mediaQuery("md")}{
    top: 7%;
    right: ${({$pop})=> $pop? "3%": "-100%"};
    max-width: 44%;
  }
  ${mediaQuery("lg")}{
    max-width: 33%;
  }
`;

export const StyledAlertMessage = styled.p<StyledAlertProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color:  ${({theme})=> theme.black};
  font-weight: 600;
  font-size: 1.4rem;
  gap: 1.4rem;
  & > svg {
    flex-shrink: 0;
  }
`;